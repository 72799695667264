.tile {
  flex-grow: 1;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-items: center;
  align-items: stretch;
  overflow: hidden;
}

.button {
  flex-grow: 1;
  overflow: hidden;
  padding: 0.5rem;
}

.active {
  background-color: #e6f8fb;
}

.disabled {
  opacity: 0.55;
}

.card {
  overflow: hidden;
}

.label {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
